import React from 'react';
import Identicon from 'identicon.js';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FormatListNumberedRtlOutlinedIcon from '@material-ui/icons/FormatListNumberedRtlOutlined';
import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SecurityIcon from '@material-ui/icons/Security';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import Tooltip from '@material-ui/core/Tooltip';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
    icon: {
      marginRight: theme.spacing(2),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      height: 0,
      paddingTop: '56.25%', // 16:9
      marginTop:'30',
    },
    cardContent: {
      flexGrow: 1,
    },
    cardActions:{
      justifyContent: 'space-between'
    },
    cardIcon:{
        fontSize: 18,
    },
    cardButton:{
        height: 18,
    },
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
      },
    nested: {
        paddingLeft: theme.spacing(3),
        maxHeight:30
      },
    listItem:{
        maxHeight:30,
    },
    listItemText:{
        paddingLeft: theme.spacing(0),
        alignSelf:"right"
    },
    listItemIcon:{
        minWidth:"30px"
    }

  }));

const getShorterAddress=(address)=>{
    if ((!address)||address.length<10) return address;
  
    return address.substring(0, 6) + '...' + address.substring(address.length-4, address.length)
  
  };

const getShorterLicenseCode=(licenseCode)=>{
    if ((!licenseCode)||licenseCode.length<20) return licenseCode;
  
    return licenseCode.substring(0, 19) + '...'
  
  };


function Mark(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const [openUser, setOpenUser] = React.useState(false);

    const handleClickUserList = () => {
      setOpenUser(!openUser);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleMenuItemClick = (event) => {
      setAnchorEl(null);
      console.log("Click on " + event.currentTarget.name);
      if (event.currentTarget.name)
      {
        let tipAmount = window.web3.utils.toWei('10', 'Ether')
        props.tipAUser(event.currentTarget.name, tipAmount)
      }
    };
    
    const isOwner=(props.image.author==props.account||props.image.owner==props.account);
    let ownACopy=false;
    if (!isOwner)
    {
        ownACopy=props.image.licenseCode?.length>0;
    }
    
    const currencySymbol=(props.image.currencyType==1)?(' MARK'):(' ETH');

    const [copied, setCopied] = React.useState(false);

  return (
    <>
            <Grid item xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <Avatar alt={props.image.author} src={`data:image/png;base64,${new Identicon(props.image.author, 30).toString()}`} />
                  }
                  action={
                    <IconButton aria-label="settings" onClick={handleMenuClick}>
                      <MoreVertIcon />
                    </IconButton>
                  }
                  title={props.image.title.split('|')[0]}
                  subheader=
                  {
                    <>
                      <Typography variant="caption" color="textSecondary" component="p">
                        {'Owned by ' + getShorterAddress(props.image.owner)} 
                      </Typography>
                      <Typography variant="subtitle2" color="textSecondary" component="p">
                        {'Price: ' + window.web3.utils.fromWei(props.image.price.toString(), 'Ether')  + currencySymbol}
                      </Typography>
                    </>
                  }

                />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  {props.image.users.map((user, index) => (
                    <MenuItem key={index} name={user} onClick={(event) => {
                      let tipAmount = window.web3.utils.toWei('10', 'Ether')
                      props.tipAUser(user, tipAmount)
                    }}>Tip 10 Mark to User {getShorterAddress(user)}</MenuItem>
                  ))}
                </Menu>
                  <CardMedia
                    className={classes.cardMedia}
                    image={`https://ipfs.infura.io/ipfs/${props.image.hash}`}
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography variant="body2" gutterBottom>
                      {props.image.title.split('|')[1]}
                    </Typography>
                    
                  </CardContent>
                  <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    className={classes.root}
                    >
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <BorderColorRoundedIcon className={classes.cardIcon}/>
                        </ListItemIcon>
                        <Typography className={classes.listItemText} variant="caption" display="block" gutterBottom>
                            { 'Created by ' + getShorterAddress(props.image.author)}
                        </Typography>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <LoyaltyOutlinedIcon className={classes.cardIcon}/>
                        </ListItemIcon>
                        <Typography className={classes.listItemText} variant="caption" display="block" gutterBottom>
                            { props.image.royalties + '% royalties'}
                        </Typography>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <FormatListNumberedRtlOutlinedIcon className={classes.cardIcon}/>
                        </ListItemIcon>
                        <Typography variant="caption" display="block" gutterBottom>
                            {(props.image.numOfCopies>1)?(props.image.numOfCopies + ' copies (' + props.image.users.length + ' of ' + props.image.numOfCopies + ' sold)'
                                ):('Single copy  ')}
                        </Typography>
                        
                        

                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <FavoriteIcon className={classes.cardIcon}/>
                        </ListItemIcon>
                        <Typography  variant="caption" display="block" gutterBottom>
                            { 'Tips: ' + window.web3.utils.fromWei(props.image.tipAmount.toString(), 'Ether') + ' MARK  '}
                        </Typography>
                    </ListItem>

                    {(isOwner && props.image.numOfCopies>1 ) && (<>
                    
                    <ListItem button onClick={handleClickUserList} className={classes.listItem}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <PeopleOutlineIcon className={classes.cardIcon}/>
                        </ListItemIcon>
                        <Typography variant="caption" display="block" gutterBottom>
                            User List
                        </Typography>
                        {openUser ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openUser} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>

                        {props.image.users.map((user, key) => (
                            <ListItem button className={classes.nested} key={key}>
                                <ListItemIcon className={classes.listItemIcon}>
                                    <PersonOutlineIcon className={classes.cardIcon}/>
                                </ListItemIcon>
                                <Typography variant="caption" display="block" gutterBottom>
                                    {getShorterAddress(user)}
                                </Typography>
                            
                            </ListItem>
                        ))}
                        </List>
                    </Collapse>

                </>)}
                    
                    {(isOwner||ownACopy||props.image.price==0) && (<>
                    
                        <ListItem button onClick={handleClick} className={classes.listItem}>
                            <ListItemIcon className={classes.listItemIcon}>
                                <SecurityIcon className={classes.cardIcon}/>
                            </ListItemIcon>
                            <Typography variant="caption" display="block" gutterBottom>
                                Lincese Information
                            </Typography>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button className={classes.nested}>
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <VerifiedUserOutlinedIcon className={classes.cardIcon}/>
                                    </ListItemIcon>
                                    <Typography variant="caption" display="block" gutterBottom>
                                        {'Product Code: ' + props.image.productCode.split('|')[0]}
                                    </Typography>
                                    
                                </ListItem>
                                <ListItem button className={classes.nested}>
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <FingerprintIcon className={classes.cardIcon}/>
                                    </ListItemIcon>
                                    <CopyToClipboard
                                      text={props.image.licenseCode}
                                      onCopy={() => setCopied(true)}
                                    >
                                      <Tooltip title={copied?'Copied':'Copy To Clipboard'} placement="bottom">
                                        <Typography variant="caption" display="block" gutterBottom>
                                            {getShorterLicenseCode(props.image.licenseCode)}
                                        </Typography>
                                      </Tooltip>
                                    </CopyToClipboard>
                                </ListItem>
                                <ListItem button className={classes.nested}>
                                    <ListItemIcon className={classes.listItemIcon}>
                                        <CloudDownloadIcon className={classes.cardIcon}/>
                                    </ListItemIcon>
                                    <Typography variant="caption" display="block" gutterBottom>
                                        {props.image.productCode.split('|')[1]}
                                    </Typography>
                                    
                                </ListItem>


                            </List>
                        </Collapse>

                    </>)}


                  </List>

                  <CardActions className={classes.cardActions}>
                    <Button variant="outlined" size="small" color="primary" name={props.image.id}
                          onClick={(event) => {
                            let tipAmount = window.web3.utils.toWei('10', 'Ether')
                            props.tipImageOwner(event.currentTarget.name, tipAmount)
                          }}>
                      TIP 10 MARK
                    </Button>
                    <Button  variant="outlined" size="small" color="primary" name={props.image.id}
                          onClick={(event) => {
                            let price = props.image.price
                            if (props.image.currencyType==1)
                              props.purchaseImageWithMark(event.currentTarget.name, price)
                            else
                              props.purchaseImage(event.currentTarget.name, price)
                          }}>
                            <Typography variant="caption" display="block" gutterBottom>
                            Buy
                            </Typography>
                            
                    </Button>

                  </CardActions>
                </Card>
              </Grid>
    </>
  );
}

export default Mark;
