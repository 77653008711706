import React from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import SectionHeader from "./SectionHeader";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckIcon from "@material-ui/icons/Check";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { Link } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(3),
  },
  price: {
    display: "flex",
    alignItems: "baseline",
  },
  listItem: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  perkIcon: {
    minWidth: 34,
    color: theme.palette.success.main,
  },
}));

function PricingSection(props) {
  const classes = useStyles();

  const auth = useAuth();

  const items = [
    {
      id: "user",
      name: "User",
      price: "100",
      perks: [
        "Earn free 500 Marks once you purchase your first software on TeleMarks",
        "You can resale the software license you own and earn Ethereum",
        "You can use your Marks to tip the software authors",
      ],
      link:"/marketplace",
    },
    {
      id: "vendor",
      name: "Vendor",
      price: "500",
      perks: [
        "Earn free 500 Marks once you list your first software on TeleMarks",
        "Your buyers can be your resalers of your software and just collect the royal fees",
        "You can use your Marks to reward your users",
      ],
      link:"/createmark",
    },
    {
      id: "investor",
      name: "Investor",
      price: "1000",
      perks: [
        "Earn extra 1000 marks for the first 100 TeleMarks token investors",
        "Tap in web3 incredible growth potential",
      ],
      link:"https://app.uniswap.org/#/swap",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Grid container={true} justify="center" spacing={4}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} md={4} key={index}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Typography variant="h6" component="h2">
                    {item.name}
                  </Typography>
                  <Box className={classes.price} mt={1}>
                    <Typography variant="h3">{item.price}</Typography>
                    <Typography variant="h4" color="textSecondary">
                    &nbsp;Marks
                    </Typography>
                  </Box>

                  {item.description && (
                    <Box mt={2}>
                      <Typography component="p" color="textSecondary">
                        {item.description}
                      </Typography>
                    </Box>
                  )}

                  {item.perks && (
                    <Box mt={1}>
                      <List aria-label="perks">
                        {item.perks.map((perk, index) => (
                          <ListItem
                            className={classes.listItem}
                            disableGutters={true}
                            key={index}
                          >
                            <ListItemIcon className={classes.perkIcon}>
                              <CheckIcon />
                            </ListItemIcon>
                            <ListItemText>{perk}</ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  )}

                  <Box mt="auto" pt={3}>
                    {
                    (item.id=='investor')?(
                      <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth={true}
                      href="https://app.uniswap.org/#/swap"
                      target="_blank"
                    >
                      Choose
                    </Button>
                    ):(
                      <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth={true}
                      component={Link}
                      to={
                        item.link
                      }
                    >
                      Choose
                    </Button>
                    )
                    }
                    
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default PricingSection;
