import React, { useState } from 'react';
import Button from '@material-ui/core/Button';

import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';


const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  currencyOption:{
    width: '25ch',
  },
}));

const currencies = [
  {
    value: 'Ether',
    label: 'ETH',
  },
  {
    value: 'Mark',
    label: 'MARK',
  },
];


function CreateMarkPage(props) {
    const classes = useStyles();
    const [description, setDescription] = useState('');
    const [title, setTitle]=useState('');
    const [price, setPrice]=useState(0.1)
    const [productCode, setProductCode]=useState('')
    const [unlockable, setUnlockable]=useState('')
    const [royalties, setRoyalties]=useState(50);
    const [numOfCopies, setNumOfCopies]=useState(5);
    //const [currencyType, setCurrencyType]=useState(0);

    const [currency, setCurrency] = React.useState('Ether');

    const handleChange = (event) => {
      setCurrency(event.target.value);
      //setCurrencyType((currency=='Ether')?0:1)
      //console.log('currencyType=' + currencyType)
    };

    return (

    <React.Fragment>
      <CssBaseline />
      <main>

        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">

          <React.Fragment>
          <Typography variant="h6" gutterBottom>
            Create a Mark
          </Typography>
          <form onSubmit={(event) => {
                event.preventDefault()
                console.log((currency=='Ether')?0:1)
                props.uploadImage(title, description,window.web3.utils.toWei(price.toString(), 'Ether') ,productCode,unlockable,royalties,numOfCopies,((currency=='Ether')?0:1))
              }} >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  id="title"
                  name="title"
                  label="Title"
                  value={title}
                  onInput={ e=>setTitle(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="description"
                  name="description"
                  label="Description (optional)"
                  value={description}
                  onInput={ e=>setDescription(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField className={classes.currencyOption}
                  id="standard-select-currency"
                  select
                  label="Select your currency"
                  value={currency}
                  onChange={handleChange}
                  
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                
                <TextField 
                  required
                  type="text"
                  value={0.1}
                  InputProps={{
                      inputProps: { 
                        maxLength: 13,
                        min: 0 
                      }
                  }}
                  label="Price"
                  value={price}
                  onInput={ e=>setPrice(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField 
                  required
                  type="number"
                  value={20}
                  InputProps={{
                      inputProps: { 
                        min: 0 
                      }
                  }}
                  label="Royalties %"
                  value={royalties}
                  onInput={ e=>setRoyalties(e.target.value)}
                />
                
              </Grid>
              <Grid item xs={12}>
                <TextField 
                  required
                  type="number"
                  value={1}
                  InputProps={{
                      inputProps: { 
                        min: 0 
                      }
                  }}
                  label="Number of Copies"
                  value={numOfCopies}
                  onInput={ e=>setNumOfCopies(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" display="block" gutterBottom>
                  Select a picture for your item (optional)
                </Typography>
                <input 
                        type='file' 
                        accept=".jpg, .jpeg, .png, .bmp, .gif" 
                        //className={classes.input}
                        id="contained-button-file"
                        onChange={props.captureFile}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id="productCode"
                  name="productCode"
                  label="Product/Entitlement code"
                  value={productCode}
                  onInput={ e=>setProductCode(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                  
                  <TextField
                    id="unlockable"
                    name="unlockable"
                    label="Download link, special instructions..."
                    value={unlockable}
                    onInput={ e=>setUnlockable(e.target.value)}
                    fullWidth
                  />
                </Grid>
              
            </Grid>
            <br/>
            <Button variant="contained" color="primary" type="submit">
              Create Mark
            </Button>
          </form>
          </React.Fragment>
          </Container>
        </div>
      </main>
    </React.Fragment>

    );
}

export default CreateMarkPage;