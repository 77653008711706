import React from "react";
import PricingSection from "./../components/PricingSection";

function PricingPage(props) {
  return (
    <PricingSection
      bgColor="default"
      size="medium"
      bgImage=""
      bgImageOpacity={1}
      title="Rewards"
      subtitle="TeleMarks is totally free.  Everyone can participate to earn its social token Mark."
    />
  );
}

export default PricingPage;
