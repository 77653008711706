import React, { Component } from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import PricingPage from "./pricing";
import ContactPage from "./contact";
import DashboardPage from "./dashboard";
import SettingsPage from "./settings";
import PurchasePage from "./purchase";
import AuthPage from "./auth";
import { Switch, Route, Router } from "./../util/router.js";
import NotFoundPage from "./not-found.js";
import Footer from "./../components/Footer";
import "./../util/analytics.js";
import { ThemeProvider } from "./../util/theme.js";

import TeleMarks from '../abis/TeleMarks.json'
import MarkToken from '../abis/MarkToken.json'
import Web3 from 'web3';
import MarketplacePage from "./marketplace";
import MyMarksPage from "./myMarks";
import CreateMarkPage from "./createMark";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

//import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Authereum from "authereum";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

//Declare IPFS
const ipfsClient = require('ipfs-http-client')
const ipfs = ipfsClient({ host: 'ipfs.infura.io', port: 5001, protocol: 'https' }) // leaving out the arguments will default to these values




class App extends Component {

  async handleWalletConnect(){
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: 'fd9f82fc8f6c44e6a0a4c3d7c108f2e1'
        }
      },
      authereum: {
        package: Authereum
      },
    }
  
    const web3Modal = new Web3Modal({
      network: "ropsten", // optional
      cacheProvider: true, // optional
      providerOptions // required
    });
    
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    window.web3=web3;
  }

  handleEthereum() {
    const { ethereum } = window;
    if (ethereum && ethereum.isMetaMask) {
      window.ethereum.enable()
      console.log('Ethereum successfully detected!');
      // Access the decentralized web!
    } else {
      let seeds = require('../seeds.json');
      this.setState({
        images: seeds
      })
      this.setState({ loading: false })
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
    }
  }

  async componentWillMount() {
    //await this.loadWeb3()
    await this.loadBlockchainData()
    await this.loadSeeData()
  }

  async loadSeeData(){
    if (this.state.imageCount<1)
    {
      let seeds = require('../seeds.json');
      const seedCount=seeds.length
      this.setState({
        images: seeds
      })
      this.setState({imagesCount:seedCount})
      this.setState({ loading: false })
    }
  }

  async loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else {
      window.addEventListener('ethereum#initialized', this.handleEthereum, {
        once: true,
      });
    
      // If the event is not dispatched by the end of the timeout,
      // the user probably doesn't have MetaMask installed.
      setTimeout(this.handleEthereum, 3000); // 3 seconds
    }
  }



  async loadBlockchainData() {

    

    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: 'fd9f82fc8f6c44e6a0a4c3d7c108f2e1'
        }
      },
      authereum: {
        package: Authereum
      },
    };
    
    const web3Modal = new Web3Modal({
      network: "ropsten", // optional
      cacheProvider: true, // optional
      providerOptions // required
    });
    
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    window.web3=web3;

    //const web3 = window.web3
    

    // Load account
    const accounts = await web3.eth.getAccounts()
    this.setState({ account: accounts[0] })

    // Network ID
    const networkId = await web3.eth.net.getId()

    // Load MarkToken
    const markTokenData = MarkToken.networks[networkId]
    if(markTokenData) {
      const markToken = new web3.eth.Contract(MarkToken.abi, markTokenData.address)
      this.setState({ markToken })
      let markTokenBalance = await markToken.methods.balanceOf(this.state.account).call()
      this.setState({ markTokenBalance: markTokenBalance.toString() })
    } else {
      window.alert('MarkToken contract not deployed to detected network.')
    }

    var CryptoJS = require("crypto-js");
    const secritKey = 'my-secret-key@123';

    const networkData = TeleMarks.networks[networkId]
    if(networkData) {
      const teleMarks = new web3.eth.Contract(TeleMarks.abi, networkData.address)
      this.setState({ teleMarks })
      const imagesCount = await teleMarks.methods.imageCount().call()
      this.setState({ imagesCount:imagesCount })
      this.setState({
        images: []
      })
      // Load images
      for (var i = 1; i <= imagesCount; i++) {
        let image = await teleMarks.methods.images(i).call()
        let users=[]
        for (var j = 0; j < image.numOfCopies; j++)
        {
          const user = await teleMarks.methods.users(i,j).call()
          if (user=='0x0000000000000000000000000000000000000000') break;
          users.push(user)
        }
        image.users=users
        //console.log(JSON.stringify(image))
        this.setState({
          images: [...this.state.images, image]
        })
        if (this.state.account && this.state.account.length>0)
        {
          if (image.author==this.state.account)
          {
            image.licenseCode=CryptoJS.AES.encrypt(this.state.account, image.productCode + secritKey).toString();
            this.setState({
              imagesCreated: [...this.state.imagesCreated, image]
            })
            if(image.owner==this.state.account)
            {
              this.setState({
                imagesOwned: [...this.state.imagesOwned, image]
              })
            }
          }
          else if(image.owner==this.state.account)
          {
            image.licenseCode=CryptoJS.AES.encrypt(this.state.account, image.productCode + secritKey).toString();
            this.setState({
              imagesOwned: [...this.state.imagesOwned, image]
            })
          }
          else if (image.numOfCopies>1 && image.users && image.users.length>0)
          {

            for (var j = 0; j < image.users.length; j++)
            {
              if (image.users[j]==this.state.account)
              {
                image.licenseCode=CryptoJS.AES.encrypt(this.state.account, image.productCode + secritKey).toString();
                this.setState({
                  imagesOwned: [...this.state.imagesOwned, image]
                })
                break
              }
            }
          }
        }

        
      }

      

      // Sort images. Show highest tipped images first
      this.setState({
        images: this.state.images.sort((a,b) => b.tipAmount - a.tipAmount )
      })
      this.setState({ loading: false})
      
    } else {
      window.alert('TeleMarks contract not deployed to detected network.')
    }

    
  }



  captureFile = event => {

    event.preventDefault()
    const file = event.target.files[0]
    const reader = new window.FileReader()
    reader.readAsArrayBuffer(file)

    reader.onloadend = () => {
      this.setState({ buffer: Buffer(reader.result) })
      console.log('buffer', this.state.buffer)
    }
  }

  uploadImage = (title, description, price, productCode, unlockable, royalties, numOfCopies, currencyType) => {
    //console.log("Submitting file to ipfs...")

    //adding file to the IPFS
    ipfs.add(this.state.buffer, (error, result) => {
      //console.log('Ipfs result', result)
      if(error) {
        console.error(error)
        return
      }

      this.setState({ loading: true })
      this.state.teleMarks.methods.uploadImage(result[0].hash,title + '|' + description, price, productCode+'|'+ unlockable,royalties,numOfCopies,currencyType).send({ from: this.state.account }).on('transactionHash', (hash) => {
        this.setState({ loading: false })
      })
    })
  }

  tipImageOwner(id, tipAmount) {
    this.setState({ loading: true })
    this.state.markToken.methods.approve(this.state.teleMarks._address, tipAmount).send({ from: this.state.account }).on('transactionHash', (hash) => {
      this.state.teleMarks.methods.tipImageOwner(id, tipAmount).send({ from: this.state.account, gas:200000}).on('transactionHash', (hash) => {
        this.setState({ loading: false })
      })
    })
  }

  tipAUser(receipt, tipAmount) {
    this.setState({ loading: true })
    this.state.markToken.methods.approve(receipt, tipAmount).send({ from: this.state.account }).on('transactionHash', (hash) => {
      this.state.teleMarks.methods.tipAUser(receipt, tipAmount).send({ from: this.state.account, gas:200000}).on('transactionHash', (hash) => {
        this.setState({ loading: false })
      })
    })
  }

  purchaseImage(id, price) {
    this.setState({ loading: true })
    this.state.teleMarks.methods.purchaseImage(id).send({ from: this.state.account, value: price,gas:200000}).on('transactionHash', (hash) => {
      this.setState({ loading: false })
    })
  }

  purchaseImageWithMark(id, price) {
    if (parseInt(this.state.markTokenBalance)<price)
    {
      window.alert('Your fund is not sufficient to buy this item.')
    }
    else
    {
      this.setState({ loading: true })
      this.state.markToken.methods.approve(this.state.teleMarks._address, price).send({ from: this.state.account }).on('transactionHash', (hash) => {
        this.state.teleMarks.methods.purchaseImageWithMark(id).send({ from: this.state.account, gas:200000}).on('transactionHash', (hash) => {
          this.setState({ loading: false })
        })
      })
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      account: '',
      markToken:{},
      teleMarks: null,
      imagesCount: 0,
      images: [],
      markTokenBalance: '0',
      loading: true,
      imagesCreated:[],
      imagesOwned:[]
    }

    this.uploadImage = this.uploadImage.bind(this)
    this.tipImageOwner = this.tipImageOwner.bind(this)
    this.tipAUser=this.tipAUser.bind(this)
    this.purchaseImage = this.purchaseImage.bind(this)
    this.purchaseImageWithMark = this.purchaseImageWithMark.bind(this)
    this.captureFile = this.captureFile.bind(this)

    
    

  }
  render() {
    //const classes = useStyles();
    const walletConnectSection=(

      <div >
          <Container maxWidth="sm">
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              Connect with one of available wallet providers or create a new wallet.
            </Typography>
            <div >
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button variant="contained" color="primary" onClick={this.handleWalletConnect}>
                    Connect
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      )
    return (
    <ThemeProvider>
      <Router>
        <>
          <Navbar
            color="default"
            logo="logo.png"
            logoInverted="logo2.svg"
            account={this.state.account}
          />

          <Switch>
            <Route exact path="/" component={IndexPage} />

            {/* <Route exact path="/marketplace" component={MarkplacePage({items:menbers})} /> */}
            <Route exact path="/marketplace">

              { (this.state.imagesCount<1)
                ? <div id="loader" className="text-center mt-5"><p>Loading...</p></div>
                : <MarketplacePage
                    images={this.state.images}
                    captureFile={this.captureFile}
                    uploadImage={this.uploadImage}
                    tipImageOwner={this.tipImageOwner}
                    tipAUser={this.tipAUser}
                    purchaseImage={this.purchaseImage}
                    purchaseImageWithMark={this.purchaseImageWithMark}
                    account={this.state.account}
                  />
              }
            </Route>

            <Route exact path="/mymarks">
              { this.state.account.length<1
                ? walletConnectSection
                : <MyMarksPage
                    imagesCreated={this.state.imagesCreated}
                    imagesOwned={this.state.imagesOwned}
                    captureFile={this.captureFile}
                    uploadImage={this.uploadImage}
                    tipImageOwner={this.tipImageOwner}
                    tipAUser={this.tipAUser}
                    purchaseImage={this.purchaseImage}
                    purchaseImageWithMark={this.purchaseImageWithMark}
                    markTokenBalance={this.state.markTokenBalance}
                    account={this.state.account}
                  />
              }
            </Route>

            <Route exact path="/createmark">
              { this.state.account.length<1
                ? <div id="loader" className="text-center mt-5"><p>Loading...</p></div>
                : <CreateMarkPage
                    images={this.state.images}
                    captureFile={this.captureFile}
                    uploadImage={this.uploadImage}
                    tipImageOwner={this.tipImageOwner}
                    tipAUser={this.tipAUser}
                    purchaseImage={this.purchaseImage}
                    purchaseImageWithMark={this.purchaseImageWithMark}
                  />
              }
            </Route>

            <Route exact path="/about" component={AboutPage} />

            <Route exact path="/faq" component={FaqPage} />

            <Route exact path="/pricing" component={PricingPage} />

            <Route exact path="/contact" component={ContactPage} />

            <Route exact path="/dashboard" component={DashboardPage} />

            <Route exact path="/settings/:section" component={SettingsPage} />

            <Route exact path="/purchase/:plan" component={PurchasePage} />

            <Route exact path="/auth/:type" component={AuthPage} />

            <Route component={NotFoundPage} />
          </Switch>
          <Footer
            bgColor="light"
            size="normal"
            bgImage=""
            bgImageOpacity={1}
            description="A decentralized license management and software listing platform."
            copyright="© 2021 TeleMarks"
            logo="logo.png"
            logoInverted="logo2.png"
            sticky={true}
          />
        </>
      </Router>
    </ThemeProvider>
  );
  }
}

export default App;
