import React from "react";
import Section from "./Section";
import Container from "@material-ui/core/Container";
import SectionHeader from "./SectionHeader";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
}));

function FaqSection(props) {
  const classes = useStyles();

  const items = [
    {
      question: "Is it free to list a software and purchase one from TeleMarks?",
      answer:
        "Yes! You don't pay any commissions to TeleMarks on any activities.  We even reward you some Mark tokens when you list your software or make a purchase from other vendors.",
    },
    {
      question: "Should I change my current license components in order to work with TeleMarks?",
      answer:
        "No, you can just keep your current license components. TeleMarks provides you with an alternative way to distribute your licenses to your users. It is free, more secure and very efficient.",
    },
    {
      question: "Can I list my software with 0 price?",
      answer:
        "Absolutely! You are encouraged to list your software, open-source projects or even system architect designs for free if you believe they can benefit others. You can still receive rewards with Marks just like any other software.",
    },
    {
      question: "How soon can I recieve my payment when my users resale my items?",
      answer:
        "Usually a few minutes.  You will receive your payment almost simultaneously once your users finish the transaction.",
    },
    {
      question: "Will my users also get paid when they resale my items?",
      answer:
        "Yes, but it depends how you setup your royalty percentage.  We believe this is an efficient  way for you to distribute your software and services to more users.",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography variant="h6">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`}>
              <Typography>{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
