import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Mark from '../components/mark/Mark';
import { Card, CardContent, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flex: '1 0 auto',
    margin: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0, 1),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    
  },
  cardMedia: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  cardActions:{
    justifyContent: 'space-between'
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));


function MyMarksPage(props) {
    const classes = useStyles();
    return (

    <React.Fragment>
      <CssBaseline />
      <main>

        <Container className={classes.cardGrid} maxWidth="md">
        <div className={classes.root}>
          
          <div className={classes.container}>
            <Paper className={classes.paper}>
              <Typography variant="h5" component="h2" color="textPrimary">
              {props.imagesCreated.length}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                Total Marks Created
              </Typography>
            </Paper>
            <Paper className={classes.paper}>
            <Typography variant="h5" component="h2" color="textPrimary">
              {props.imagesOwned.length}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                Total Marks Owned
              </Typography>
            </Paper>
            <Paper className={classes.paper}>
              <Typography variant="h5" component="h2" color="textPrimary">
                {window.web3.utils.fromWei(props.markTokenBalance, 'Ether').replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                TeleMarks Totoken Balance
              </Typography>
            </Paper>
          </div>
        </div>


        <Accordion expanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Marks I created</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4}>
              {props.imagesCreated.map((image, key) => (
                <Mark image={image} tipImageOwner={props.tipImageOwner} tipAUser={props.tipAUser} purchaseImage={props.purchaseImage} purchaseImageWithMark={props.purchaseImageWithMark} account={props.account} key={key}/>
                
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Marks I own</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4}>
              {props.imagesOwned.map((image, key) => (
                <Mark image={image} tipImageOwner={props.tipImageOwner} tipAUser={props.tipAUser} purchaseImage={props.purchaseImage} />
                
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
        </Container>
      </main>
    </React.Fragment>

    );
}

export default MyMarksPage;